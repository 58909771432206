import React, { Component, Fragment } from 'react'
import { Home } from 'react-feather'
import { SEO, Layout, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb, Image } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

class MissionVision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false
    }
  }

  handleToggleOverlay = (index) => {
    this.setState({
      isOpen: !this.state.isOpen,
      photoIndex: index
    })
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const data = this.props.pageContext
    const { favicon } = data.topNav
    const { mission, vision, coreValues, banners } = data.missionVision
    const bannerCount = banners.length
    const maxImageToDisplay = 5
    let images = []
    banners.map(banner => images.push(banner.imageUrl))

    return (
      <Fragment>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
        <SEO title="Mission, Vision, and Core Values" favicon={favicon}/>
        <Layout activeNav="about-us" data={data.topNav}>
          <Container className="my-4 md-mt-10 sm-mt-7">
            <Breadcrumb className="breadcrumb-wrapper mx-0">
              <Breadcrumb.Item href="/">
                <Home className="text-violet mr-2" size={14} />
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/about-us/company-profile">
                About Us
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Mission, Vision, and Core Values</Breadcrumb.Item>
            </Breadcrumb>

            <Row className="mt-3 mb-5">
              <Col lg={7}>
                <h2 className="text-dark-slate-blue mb-3">Mission</h2>
                {mission
                  ? <div dangerouslySetInnerHTML={{ __html: mission }} />
                  : <p className="text-muted">Not Available</p>}
                <h2 className="text-dark-slate-blue mt-4 mb-3">Vision</h2>
                {vision
                  ? <div dangerouslySetInnerHTML={{ __html: vision }} />
                  : <p className="text-muted">Not Available</p>}
                <h2 className="text-dark-slate-blue mt-4 mb-3">Core Values</h2>
                {coreValues
                  ? <div dangerouslySetInnerHTML={{ __html: coreValues }} />
                  : <p className="text-muted">Not Available</p>}
              </Col>
              <Col lg={5}>
                <Row>
                  {banners && bannerCount > 0 ? banners.map((banner, i) => i === 0
                    ? <Col lg={12} className="h-300px px-1 pb-3 cursor-pointer">
                      <Image src={banner.imageUrl} onClick={() => this.handleToggleOverlay(i)} alt="banner" className="w-100 h-100 image-cover" />
                    </Col>
                    : (i < 4
                      ? <Col lg={3} className="px-1 mb-3 cursor-pointer">
                        <Image src={banner.imageUrl} onClick={() => this.handleToggleOverlay(i)} alt="banner" className="w-100 h-100 image-cover mh-81" />
                      </Col>
                      : (i > 4 ? null : <Col lg={3} className="px-1 mb-3 cursor-pointer">
                        {bannerCount > 5
                          ? <button onClick={() => this.handleToggleOverlay(i)} className="bg-dark position-absolute w-faded-black h-100 faded-black d-flex justify-content-center align-items-center">
                            <h2 className="text-white text-faded-black">+{bannerCount - maxImageToDisplay}</h2>
                          </button>
                          : null}
                        <Image src={banner.imageUrl} onClick={() => this.handleToggleOverlay(i)} alt="banner" className="w-100 h-100 mh-81" />
                      </Col>))) : null}
                </Row>
              </Col>
            </Row>
          </Container>
          <HMOSlider data={data.accreditedHmos} />
        </Layout>
      </Fragment>
    )
  }
}

export default MissionVision 
